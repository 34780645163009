import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Instagram from "../templates/Instagram"
import Layout from "../components/layout"
import Image from "gatsby-image"
import SEO from "../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Button } from "../styles/Button"
import Discover from "../components/template/DiscoverTemplate"

import {
  Landing,
  Insta,
  LandingContainerDesktop,
} from "../styles/HomeStyles.js"
import {
  Image_Asset_2,
  instaIcon,
} from "../utils/imageUpload"


export const query = graphql`
  query {
    products: allShopifyCollection(filter: { handle: { eq: "shop" } }) {
      edges {
        node {
          products {
            id
            handle
            description
            title
            shopifyId
            productType
            variants {
              title
              shopifyId
            }
            images {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    gif: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/gif/" }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    productAbout: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/product/" }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    callouts: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/Callout/" }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    gallery: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/GalleryHome/" }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    otherIcons: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/icons/" }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  useEffect(() => {
    function mailchimp() {
      window.dojoRequire(["mojo/signup-forms/Loader"], function (L) {
        L.start({
          baseUrl: "mc.us19.list-manage.com",
          uuid: "ed1138f1e1cb8ec170826f50f",
          lid: "c7c62539bb",
          uniqueMethods: true,
        })
      })
    }

    const mcScript1 = document.createElement("script")
    mcScript1.type = "text/javascript"
    mcScript1.src =
      "//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js"
    mcScript1.dataset["dojoConfig"] = "usePlainJson: true, isDebug: false"
    mcScript1.onload = mailchimp

    document.body.appendChild(mcScript1)
  }, [])

  return (
    <Layout>
      <SEO title="Home" />

      <Landing landing bg={"var(--beige)"}>
        <LandingContainerDesktop>
          <div className="landing-content">
            <h2 className="title BundaySlabBold">Make time to savor</h2>
            <p className="description">
              Premium Ingredients • Fresh Frozen • Ready at a moment's notice
            </p>
            <Link to="/shop">
              <Button bg={"var(--orange)"}>Shop Now</Button>
            </Link>
            <div className="badge">
              <Image
                fadeIn={true}
                className="img"
                fluid={data.otherIcons.nodes[0].childImageSharp.fluid}
                alt=""
              />
            </div>
          </div>
          <div className="landing-image">
            <img src={Image_Asset_2} alt="" />
          </div>
        </LandingContainerDesktop>
      </Landing>

      <div>
        <Discover data={data} />
      </div>
      <Insta bg={"var(--orange)"}>
        <div className="follow-div">
          {/* <Button bg={"var(--darkBlue)"}> */}
          <img
            style={{
              width: "30px",
              height: "30px",
              marginBottom: "0",
              marginRight: "15px",
            }}
            src={instaIcon}
          />{" "}
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/savorly.us/"
          >
            Follow us on instagram
          </OutboundLink>
          {/* </Button> */}
        </div>
        <div className="instagram-wrapper">
          <Instagram />
        </div>
      </Insta>
    </Layout>
  )
}

export default IndexPage
