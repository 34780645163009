import React, { useEffect, useState } from "react"
import instaOne from "../dev_assets/InstagramPics/one.png"
import instaTwo from "../dev_assets/InstagramPics/two.png"
import instaThree from "../dev_assets/InstagramPics/three.png"
import instaFour from "../dev_assets/InstagramPics/four.png"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Instagram = (props) => {
  const [posts, setPosts] = useState(null)
  const [instaPosts] = useState(null)
  const [defaultPics] = useState([
    instaOne,
    instaTwo,
    instaThree,
    instaFour,
  ])

  useEffect(() => {
    function getRandomThree(defaultPics) {
      const randomizedPics = [...defaultPics]
      while (randomizedPics.length !== 4) {
        const index = Math.floor(Math.random() * defaultPics.length)
        randomizedPics.splice(index, 1)
      }

      return randomizedPics
    }
    setPosts(getRandomThree(defaultPics))

    //if error
  }, [defaultPics])

  if (instaPosts) {
    return (
      <>
        {instaPosts.map((post, i) => {
          return (
            <WrapperImg key={i}>
              <OutboundLink
                href={post.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={post.media_url} alt="Savorly Instagram" />
              </OutboundLink>
            </WrapperImg>
          )
        })}
      </>
    )
  }

  if (posts) {
    return (
      <>
        {posts.map((post, i) => {
          return (
            <WrapperImg key={i}>
              <img src={post} alt="Savorly Instagram" />
            </WrapperImg>
          )
        })}
      </>
    )
  }

  return <div></div>
}

const WrapperImg = styled.div`
 /* height: 400px; */

display:flex;
align-items:center;
  overflow: hidden;

  img {
    display: flex;
    margin: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
    transition: transform 3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`
export default Instagram
