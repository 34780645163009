import styled from "styled-components"
import img from "../dev_assets/HomePage/3rd_Banner_MezzeBites.jpg"
import img1 from "../dev_assets/HomePage/2nd_Banner_MiniPies.jpg"

const Landing = styled.div`
height: 70.8vh;
  background-color: ${(props) => props.bg};
  background-attachment: ${(props) => (props.fixed ? "fixed" : "")};

  background-size: ${(props) => (props.cover ? "cover" : "contain")};

  background-repeat: ${(props) => (props.noRepeat ? "no-repeat" : "")};

  background-position: ${(props) => (props.position ? "50% 50%" : "")};
  width: 100vw;

  @media (max-width: 768px) {
    height:100%;
    background-attachment: inherit;
    background-size: ${(props) => (props.landing ? "cover" : "")};
    background-position: ${(props) => (props.landing ? "50% 50%" : "")};
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  }

  .buttons {
    position: absolute;
    bottom: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
    .dots {
      display: flex;
      width: 300px;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  @media (max-width: 1000px) {
    .buttons {
      bottom: auto;
      top: 85%;
    }
  }

  @media (max-width: 900px) {
    .buttons {
      bottom: auto;
      top: 75%;
    }
  }

  @media (max-width: 800px) {
    .buttons {
      bottom: auto;
      top: 70%;
    }
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .buttons {
      bottom: auto;
      top: 40%;
    }
  }

  .badge {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10;
    width: 100px;
    .img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 800px) {
      width: 70px;
    }
  }
`

export const Dots = styled.button`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: ${(props) => (props.selected ? "var(--darkBlue)" : "white")};
  border: none;
  outline: none;

  @media (max-width: 768px) {
    display: none;
  }
`

const LandingContainerDesktop = styled.div`
  height: 100%;

  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  div {
    margin: 0 auto;
    color: var(--white);
    text-align: center;
  }

  .title {
    display: flex;
    font-size: 64px;
    color: var(--darkBlue);
    flex-direction: column;
    font-weight: 600;
    align-items: center;
    margin-bottom: 0;
  }

  .description {
    /* font-size: 1.5rem; */
    font-size: 30px;
    font-weight: 450;
    color: var(--darkBlue);
  }

  .landing-image {
    height: 100%;
    /* width: 50%; */
    img {
      max-height: 100% !important;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .landing-content {
      margin-top: 50px;

      .title {
        font-size: 60px;
      }
      .landing-image {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-width: 70%;
          max-height: 100% !important;
        }
      }
    }
  }
`

const Section = styled.section`
  background-image: ${(props) =>
    props.img ? `url(${img})` : props.img1 ? `url(${img1})` : ""};

  background-repeat: no-repeat;

  background-position: center;

  background-attachment: ${(props) => (props.fixed ? "fixed" : "")};

  background-color: ${(props) => props.bg};
  height: ${(props) => props.height};
  width: 100vw;
  color: ${(props) => props.color};

  @media (max-width: 768px) {
    background-attachment: ${(props) => (props.fixed ? "scroll" : "")};
    height: ${(props) =>
      props.height === "80vh" ? "20vh" : props.about ? "fit-content" : "60vh"};
    background-repeat-y: repeat;
  }

  background-size: contain;
`

const Product = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: center;
  .section-container {
    display: flex;
    margin: 0 auto;
    /* min-height: ${(props) => props.height}; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    .MiniPieContainer {
      width: 80%;
      .miniPie {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: inherit;
      }
    }
    .learn-more-link {
      color: var(--darkBlue);
      text-decoration: underline;
    }

    .img-title {
      width: 500px;
      margin-bottom:20px;
    }

    .product-container {
      display: flex;
      flex-direction: column;
      align-items: center;


      .img-container {
        display: flex;
        justify-content: center;
      }
      .img-product {
        width: 80%;
        height: 90%;
     
      }
    }
    .shop-btn {
      background: var(--darkBlue);
      border-radius: 8px;
      padding: 10px 0;
      width: 150px;
      margin-top:20px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.4rem;
        text-decoration: none;
        color: white;
      }
    }
    .col {
      flex-direction: column;

      width: 40vw;
      height: 70%;
    }

    .col h2 {
      color: #003157;
    }

    img {
      margin: 0;
    }
    .imgProduct {
      max-height: 100%;
    }

    .imgIllust {
      max-height: 38%;
    }

    .typingEffect {
      color: rgb(244, 67, 108);
      font-weight: light;
      text-transform: uppercase;
    }

    .cursorTyper {
      color: rgba(0, 48, 87, 0.5);
    }

    .productRight {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      height: 100%;

      & img {
        margin: 0;
      }
    }

    .productLeft {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      align-items: center;
      text-align: center;
      width: 100%;
    }

    .productLeft img {
      width: 100%;
    }

    @media (min-width: 768px) {
      .title-product {
        /* width: 70%; */
      }
      margin: 0 auto;

      .typingEffect {
        font-size: 2em;
      }

      .col h2 {
        font-size: 2.5rem;
      }

      .productLeft {
        & img {
          /* width: 70%; */
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0;
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      padding: 0;
    }

    /* padding: 20px 10rem; */
  }
`

const Insta = styled.div`
  background: ${(props) => props.bg};
  padding: 0 0 20px !important;
  .follow-div {
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    font-size: 1.8rem;

    h2 {
      margin: 0;
      // padding: 0 0 20px;
      font-family: FuturaPTWebMedium, FuturaPTCond-Medium !important;
    }

    a {
      text-decoration: underline;
      /* text-decoration-color: var(--darkBlue); */
      color: white;
    }
  }

  .instagram-wrapper {
    /* padding: 30px 0; */
    @media (min-width: 1024px) {
      height: 50vh;
    }

    @media (max-width: 678px) {
      --repeat: auto-fit;
      padding: 0;
      grid-gap: 0;
      div:last-child {
        display: none;
      }
    }

    @media (min-width: 679px) and (max-width: 1024px) {
      --repeat: 2;
    }

    @media (min-width: 1025px) {
      --repeat: 4;
    }

    display: grid;
    /* grid-gap:1rem; */
    grid-template-columns: repeat(
      var(--repeat, auto-fit),
      minmax(calc(250px * 1), 1fr)
    );
  }
`
export { Section, Insta, Product, Landing, LandingContainerDesktop }
