import React from "react"
import { ProductContainer, Wrapper } from "../../styles/StoreStyles.js"
import ProductStore from "../../templates/ProductStore"
import styled from "styled-components"
import BodyWrapper from "../../templates/BodyWrapper"
import Image from "gatsby-image"
import { motion } from "framer-motion"

const callOuts = [
  "Let's Party",
  "A Toast to that",
  "cheers",
  "more please",
  "yes",
  "yum",
]
const Discover = ({ data }) => {
  const allProducts = data.products.edges.map(({ node }) => node)

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  }

  return (
    <Wrapper bg="var(--white)" paddingNon>
      <DiscoverInfo>
        <div className="banner orange">
          <h1>Fresh, Frozen, and Ready in Minutes</h1>
          <h3 className="">
            At Savorly we believe entertaining should be easy enough that you
            can (and want!) to do it often.
          </h3>

          <h3 className="">
            Selling our appetizers frozen is the perfect way to do that.
          </h3>

          <div className="callout">
            <div>
              <Image
                fluid={data.callouts.nodes[0].childImageSharp.fluid}
              ></Image>
              <p>PRESERVATIVE FREE</p>
            </div>
            <div>
              <Image
                fluid={data.callouts.nodes[1].childImageSharp.fluid}
              ></Image>
              <p>JUST BAKE & SERVE</p>
            </div>
            <div>
              <Image
                fluid={data.callouts.nodes[2].childImageSharp.fluid}
              ></Image>
              <p>NON GMO</p>
            </div>
          </div>
        </div>
      </DiscoverInfo>
      <ProductContainer>
        <motion.ul
          className="container grid"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {allProducts[0].products.map((product, i) => (
            <ProductStore callOut={callOuts[i]} key={i} product={product} />
          ))}
        </motion.ul>
      </ProductContainer>
      <div style={{ background: "var(--beige)", padding: "8vh 0" }}>
        <BodyWrapper>
          <Info>
            <div className="grid">
              <div className="item">
                <div className="img-container">
                  <Image
                    width="100%"
                    fluid={data.productAbout.nodes[0].childImageSharp.fluid}
                    alt=""
                  />
                </div>
                <div className="content">
                  <div>
                    <h3>Premium ingredients, no artificial preservatives</h3>
                  </div>
                  <p>
                    Freezing is nature's preservative and it's what allows us to
                    make Savorly Party Bites & Gratins with ingredients you'd
                    prep with yourself. Our products are made using high
                    quality, fresh ingredients and then frozen to lock the
                    flavor and freshness in until you're ready for it.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="img-container">
                  <Image
                    width="100%"
                    fluid={data.productAbout.nodes[1].childImageSharp.fluid}
                    alt=""
                  />
                </div>

                <div className="content">
                  <div>
                    <h3>Ready when you need them</h3>
                  </div>
                  <p>
                    Whether you're planning for a gathering next weekend or just
                    like to be prepared for an impromptu at home happy hour, our
                    bites are a perfect one-step prep. Keep your favorites on
                    hand in the freezer and simply pop them in the oven to be
                    party perfect at a moment's notice.
                  </p>
                </div>
              </div>
            </div>
          </Info>
        </BodyWrapper>
      </div>
    </Wrapper>
  )
}

export default Discover

const DiscoverInfo = styled.div`
  /* margin-top: 100px; */

  .banner {
    padding-bottom: 10vh;
    width: 90%;
    margin: 0 auto;
    color: var(--orange);
    text-align: center;
    @media (max-width: 769px) {
      padding-bottom: 5vh;
    }
  }
  .callout {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 32px;

    p {
      font-size: 24px;
    }
    .gatsby-image-wrapper {
      margin: 0 20px;
      width: 122px;
      height: 121px;
      margin-bottom: 10px;
      margin-left: 62px;
      margin-right: 62px;

      /* height:100px; */
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h3,
  p {
    color: var(--darkBlue);
    text-align: center;
  }

  h1 {
    font-weight: 600;
    font-size: 48px;

    text-align: center;
    font-family: "BundaySlab-SemiBoldUp", sans-serif;
  }
  h3 {
    font-weight: 450;
    font-size: 30px;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
  }

  .pink {
    color: var(--pink);
  }
  .orange {
    color: var(--orange);
  }

  .grid {
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .flex-reverse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .align-center {
    align-items: center;
  }

  .img-container {
    width: 30%;
  }
  @media (max-width: 425px) {
    .callout {
      flex-direction: column;
      align-items: center;
    }

    .img-container {
      width: 60%;
    }
    .flex {
      flex-direction: column;
    }

    .flex-reverse {
      flex-direction: column;
    }
  }
`

const Gallery = styled.div`
  display: flex;
  justify-content: center;
  height: 630px;

  .gatsby-image-wrapper {
    /* height:100px; */
  }
  .container {
    display: flex;
    align-items: center;

    height: 630px;

    .item {
      width: 50%;
      height: 630px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
`

const Info = styled.div`
  .grid {
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
  .item,
  .content {
    text-transform: uppercase;

    color: var(--darkBlue);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .img-container {
    width: 55%;
    margin-bottom: 46px;
  }

  .item {
    width: 90%;
  }
  .content {
    height: 50%;
    h3 {
      font-size: 28px;
      font-weight: 700;
      margin: 0;
    }
    p {
      margin-top: 32px;
      font-size: 20px;
      font-weight: 450;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
`
