import styled from "styled-components"

export const Button = styled.button`
  background-color: ${(props) => props.bg};
  display: inline-block;
  border: none;
  border-radius: 27px;
  width: 209px;
  height: 63px;
  border: 2px solid transparent;
  min-width: 191px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bolder;
  font-size: 36px;
  color: var(--white);
  font-weight: 450;
  text-align: center;
  transition: all 0.2s;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
`
