import React, { useState } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { motion } from "framer-motion";

const ProductStore = ({ product, callOut }) => {
  const [productImgIndex] = useState(0)
  const [shadow] = useState("shadow")
  
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <motion.li className=" grid-item item" variants={item}
      key={product.id}
    >
      <Link className="item-link" to={`/product/${product.handle}`}>
        <div
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className={` ${shadow}`}
            style={{ width: "100%", height: "100%" }}
          >
            <Image
              fadeIn={true}
              imgStyle={{
                marginBottom: "0",
                objectFit: "contain",
              }}
              fluid={
                product.images[productImgIndex].localFile.childImageSharp.fluid
              }
              className="productImg"
            />
          </div>
        </div>
      <div className="productInfo-container">
        <div className="callOut">{callOut}!</div>
      </div>
        </Link>
    </motion.li>
  )
}

export default ProductStore
