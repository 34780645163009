import styled from "styled-components"

const Wrapper = styled.div`
  background-color: ${(props) => (props.bg ? props.bg : "var(--pink)")};
  // width: 100vw;
  padding-top: 10vh;

  @media (min-width: 1500px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: ${(props) => (props.paddingNon ? "none" : "5vh 0")};
  }
`
const ProductContainer = styled.div`
  background-color: var(--white);
  margin-bottom: 167px;
  width: 100vw;
  margin: 0 auto;

  height: fit-content;
  padding: 2rem;

  .grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    justify-items: center;
    align-items: center;
    display: grid;
    /* display:flex; */
    overflow-x: scroll;
    padding: 2rem;

    grid-gap: 1rem;
    justify-content: center;
    max-width: 1300px;
    margin: 0 auto;

    @media (max-width: 678px) {
      /* grid-template-columns: auto auto; */
    }
  }

  .grid-item {
    list-style-type: none;
    position: relative;
    text-align: center;

    :hover {
      .callOut {
        background: var(--darkBlue);
        color: white;
      }
    }
  }

  .item-link {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-decoration: none;
  }

  .productImg {
    width: 100%;
    height: 100%;
  }

  .productTitle {
    margin: 0;
    text-decoration: none;
    color: var(--white);
  }

  button:hover {
    cursor: pointer;
  }

  .btn-count {
    color: var(--darkBlue);
    background-color: var(--white);
    border: none;
    width: 100%;
    border-radius: 8px;
  }
  .btn-div {
    width: 30%;
    color: var(--pink);
    background-color: var(--white);
    border: none;
    border-radius: 8px;
    padding: 3px 5px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .productInfo-container {
    margin-top: 50px;
    margin-bottom: 100px;
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-around;

    .callOut {
      /* border: 1px solid var(--darkBlue); */
      /* border-radius: 12px; */
      font-weight: 450;
      font-size: 30px;
      align-items: center;
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      color: var(--white);
      background: var(--orange);

      /* font-size: 20px; */

      width: 189px;
      height: 53px;
      border-radius: 27px;
    }
  }

  .add-to-cart {
    border-radius: 8px;
    padding: 3px 5px;
    color: var(--darkBlue);
    border: 2px solid var(--white);
    width: 40%;
    background-color: rgba(255, 255, 255, 0.7);

    &:hover {
      background: var(--white);
      color: var(--darkBlue);
      @media (max-width: 769px) {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .productPrice {
    margin: 20px 0 0 0;
    color: var(--white);
    text-decoration: none;

    font-weight: bolder;
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    height: fit-content;
    padding: 1rem;
  }
`

export { ProductContainer, Wrapper }
